<template>
  <div class="main-master-wrapper">
    <Breadcrumb :breadcrumbs="breadcrumbs" :mainLabelName="currentTab"></Breadcrumb>
    <a-tabs ref="tabs" default-active-key="1">
      <a-tab-pane key="1" tab="Отклики на тендеры">
        <TabViewContainer>
          <Loader v-if="!this.$store.getters.getMasterTendersStatusOneLoaded" />
          <EmptyVue type="masterFollow" v-if="this.$store.getters.getMasterTendersStatusOneLoaded && this.$store.getters.getMasterTendersStatusOne.length === 0"></EmptyVue>
          <div
                class="cell_wrapper"
              v-if="this.$store.getters.getMasterTendersStatusOneLoaded && this.$store.getters.getMasterTendersStatusOne.length != 0"
            >
              <MasterTenderCell
                v-for="tenderEl in this.$store.getters.getMasterTendersStatusOne"
                :key="tenderEl.id"
                :tender="tenderEl.tender"
                :comingUserMessages="tenderEl.new_message_user"
                class="announcement-doing-cell"
              ></MasterTenderCell>
            </div>
            <div class="pagination">
              <a-pagination
                :current="currentPageStatusOne"
                :total="this.$store.getters.getMasterTendersStatusOneTotal"
                :showLessItems="true"
                show-less-items
                :hideOnSinglePage="true"
                :defaultPageSize="6"
                @change="onChangeStatusOnePagination"
              />
          </div>
        </TabViewContainer>
      </a-tab-pane>
      <a-tab-pane key="2" tab="В Процессе">
        <TabViewContainer>
          <Loader  v-if="!this.$store.getters.getMasterTendersStatusTwoLoaded" />
          <EmptyVue v-if="this.$store.getters.getMasterTendersStatusTwoLoaded && this.$store.getters.getMasterTendersStatusTwo.length == 0" type="masterFollow"></EmptyVue>
          <div class="cell_wrapper" v-if="this.$store.getters.getMasterTendersStatusTwoLoaded && this.$store.getters.getMasterTendersStatusTwo.length != 0">
            <MasterTenderCell
              v-for="tenderEl in this.$store.getters.getMasterTendersStatusTwo"
              :key="tenderEl.id"
              :tender="tenderEl"
              :comingUserMessages="tenderEl.new_message_user"
              class="announcement-doing-cell"
            ></MasterTenderCell>
            <div class="pagination">
              <a-pagination
                :current="currentPageStatusTwo"
                :total="this.$store.getters.getMasterTendersStatusTwoTotal"
                :showLessItems="true"
                show-less-items
                :hideOnSinglePage="true"
                :defaultPageSize="6"
                @change="onChangeStatusTwoPagination"
              />
            </div>
          </div>
        </TabViewContainer>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Завершенные">
        <TabViewContainer>
          <Loader  v-if="!this.$store.getters.getMasterTendersStatusThreeLoaded" />
          <EmptyVue v-if="this.$store.getters.getMasterTendersStatusThreeLoaded && this.$store.getters.getMasterTendersStatusThree.length == 0" type="masterFollow"></EmptyVue>
          <div class="cell_wrapper" v-if="this.$store.getters.getMasterTendersStatusThreeLoaded && this.$store.getters.getMasterTendersStatusThree.length != 0">
            <MasterTenderCell
              v-for="tenderEl in this.$store.getters.getMasterTendersStatusThree"
              :key="tenderEl.id"
              :tender="tenderEl"
              :comingUserMessages="tenderEl.new_message_user"
              class="announcement-doing-cell"
            ></MasterTenderCell>
            <div class="pagination">
              <a-pagination
                :current="currentPageStatusThree"
                :total="this.$store.getters.getMasterTendersStatusThreeTotal"
                :showLessItems="true"
                show-less-items
                :hideOnSinglePage="true"
                :defaultPageSize="6"
                @change="onChangeStatusThreePagination"
              />
            </div>
          </div>
        </TabViewContainer>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import MasterTenderCell from '@/components/MasterComponents/MasterTenderCell.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import EmptyVue from '@/components/common/EmptyVue.vue'
import TabViewContainer from '@/components/common/TabViewContainer.vue'
import Loader from '@/components/common/Loader.vue'
export default {
  components: {
    MasterTenderCell,
    Breadcrumb,
    EmptyVue,
    TabViewContainer,
    Loader
  },
  data () {
    return {
      currentPageStatusOne: 1,
      currentPageStatusTwo: 1,
      currentPageStatusThree: 1,
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        },
        {
          id: 2,
          name: 'Mои Тендеры',
          link: '/my_tenders'
        }
      ]
    }
  },
  mounted () {
    this.$store.dispatch('setMasterTendersStatusOne', 1)
    this.$store.dispatch('setMasterTendersStatusTwo', 1)
    this.$store.dispatch('setMasterTendersStatusThree', 1)
  },
  computed: {
    // TODO
    currentTab() {
      if (this.$refs.tabs && this.$refs.tabs.activeKey) {
        switch (this.$refs.tabs.activeKey) {
          case 1:
            return 'Текущие'
          case 2:
            return 'В Процессе'
          default:
            return 'Завершенные'
        }
      } else {
        return 'Текущие'
      }
    }
  },
  methods: {
    onChangeStatusOnePagination (current) {
      this.currentPageStatusOne = current
      this.$store.dispatch(
        'setMasterTendersStatusOne',
        this.currentPageStatusOne
      )
    },
    onChangeStatusTwoPagination (current) {
      this.currentPageStatusTwo = current
      this.$store.dispatch(
        'setMasterTendersStatusTwo',
        this.currentPageStatusTwo
      )
    },
    onChangeStatusThreePagination (current) {
      this.currentPageStatusThree = current
      this.$store.dispatch(
        'setMasterTendersStatusThree',
        this.currentPageStatusThree
      )
    }
  }
}
</script>

<style>
.pagination {
  width: 100%;

  display: flex;
  justify-content: flex-end;

  margin-top: 46px;
}
.main-master-wrapper {
  background-color: white;
  width: 100%;
}
.profile-label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.announcement-orders {
  width: 100%;
  height: 100%;
  padding-right: 15%;
  background-color: #f6f6f6;
  padding-left: 20px;
  padding-top: 7px;
}

.announcement-doing-page {
  background-color: #f6f6f6;
  padding-right: 15%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  padding-top: 17px;
}

.announcement-doing-cell {
  margin-top: 10px;
  background-color: white;
}

.main-master-wrapper {
  background-color: white;
  width: 100%;
  height: 100%;
}
.profile-label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.announcement-orders {
  width: 100%;
  height: 100%;
  padding-right: 15%;
  background-color: #f6f6f6;
  padding-left: 20px;
  padding-top: 7px;
}

.announcement-doing-page {
  background-color: #f6f6f6;
  padding-right: 15%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  padding-top: 17px;
}

.announcement-doing-cell {
  margin-top: 10px;
  background-color: white;
}

.pagination {
  width: 100%;

  display: flex;
  justify-content: flex-end;

  margin-top: 46px;
}

.ant-pagination-item-active {
  border-color: #b12021;
  color: #b12021;
}

.ant-pagination-item-active a {
  border-color: #b12021;
  color: #b12021;
}

.ant-pagination-item-active:focus a {
  border-color: #b12021;
  color: #b12021;
}
.ant-pagination-item-active:hover a {
  border-color: #b12021;
  color: #b12021;
}

.ant-pagination-item:focus a {
  border-color: #b12021;
  color: #b12021;
}
.ant-pagination-item:hover a {
  border-color: #b12021;
  color: #b12021;
}

.ant-pagination-disabled .ant-pagination-next {
  border-color: #b12021;
  color: #b12021;
}

.cell_wrapper{
  width: 100%;
}
</style>
